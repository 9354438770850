import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AppButton } from "./AppButton";
import Popup from "reactjs-popup";
import { VapiAPI } from "../../tools";
import { Config } from "../../config";
// @ts-ignore
import media from "../../assets/media/ring_phone.wav";
import { MicOffIcon, MicOnIcon, TelOffIcon, TelOnIcon } from "../icons";
import { ActiveCallDetail } from "../call";
import { AddToCartButton } from "./AddToCartButton";
import toast, { Toaster } from "react-hot-toast";
import { CallMessage, ConversationTranscript, Product } from "../../interfaces";
import {
  AIAssistant,
  ProductRequest,
  TranscriptRequest,
} from "../../assistant";
import { CallTranscript } from "../widgets";

interface Props extends React.ComponentProps<typeof AppButton> {
  assistantId: string;
  productId: string | number;
}

export const CallButton: React.FC<Props> = ({
  assistantId,
  productId,
  ...props
}) => {
  const audio = useMemo(() => new Audio(media), []);

  const vapi = useMemo(() => VapiAPI.instance(Config.VAPI_PUBLIC_KEY), []);
  // const product = useMemo(() => Products.findProduct(productId), [productId]);

  const [product, setProduct] = useState<Product>();

  const [callId, setCallId] = useState("");

  const [showTranscript, setShowTranscript] = useState<boolean>(false);

  const showHideTranscript = () => {
    setShowTranscript(!showTranscript);
  };

  const assistant = useMemo(
    () => (product ? new AIAssistant(product, Config.ASSISTANT_NAME) : null),
    [product]
  );

  const [messages, setMessages] = useState<ConversationTranscript[]>([]);

  const getProduct = useCallback(async () => {
    const res = await ProductRequest.find(productId);
    setProduct(res);
  }, [productId]);

  useEffect(() => {
    getProduct();
  }, [productId, getProduct]);

  const [isSpeaking, setIsSpeaking] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [showAddToCart, setShowAddToCart] = useState(false);
  const [isMoreThan10Secs, setIsMoreThan10Secs] = useState(false);
  const [buy, setBuy] = useState(false);

  const startCall = async () => {
    setIsCalling(true);
    setMessages([]);
    try {
      if (assistant) {
        console.log("Call started");
        const details = await assistant.create();
        const call = await vapi.start(details);
        console.log("Call ID: ", call?.id, "Customer ID: ", call?.customerId);
        setCallId(call?.id || "");
        audio.addEventListener("error", (e) => {
          console.error("Audio Error:", e);
          console.error("Audio Source:", media);
          console.error("Event Target:", e.target);
        });
        audio.loop = true;
        await audio.play();
      }
    } catch (error) {
      console.error("Error :", error);
    } finally {
      setIsCalling(false);
    }
  };

  const stopVoice = useCallback(() => {
    audio.pause();
    audio.currentTime = 0;
  }, [audio]);

  const reset = useCallback(() => {
    setMessages([]);
    setIsActive(false);
    setIsSpeaking(false);
    setIsMoreThan10Secs(false);
    setShowAddToCart(false);
    stopVoice();
  }, [stopVoice]);

  const stopCall = () => {
    vapi.stop();
  };

  const [open, setOpen] = useState(false);

  const [muted, setMuted] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
    if (!open) {
      stopCall();
    }
  };

  const handleMute = () => {
    const value = !muted;
    if (isActive) {
      vapi.setMuted(value);
    }
    setMuted(value);
  };
  const addToCart = (): Promise<boolean> => {
    vapi.send({
      type: "add-message",
      message: {
        role: "system",
        content:
          'The user has pressed the "Add To Cart" button. Inform the user that you have realised that they have added the product to their cart and go ahead with the next steps in the conversation.',
      },
    });
    return new Promise((resolve) => {
      const out = setTimeout(() => {
        clearTimeout(out);
        setShowAddToCart(false);
        setBuy(true);
        return resolve(true);
      }, 2000);
    });
  };

  const sendTranscript = useCallback(async () => {
    const lastSent = Config.getLastSent();
    if (callId && lastSent !== callId) {
      Config.setLastSent(callId);
      const identifier = await Config.getIdentifer();
      const tRes = TranscriptRequest.create({
        product_id: productId,
        category: product?.category || "",
        transcript: messages,
        clicked_before_10_seconds: isMoreThan10Secs,
        buy,
        // ip_address: new Config().getIdentifer(),
        ip_address: identifier,
      });
      console.log(tRes);
      reset();
    } else {
      console.log("No new transcript to send.");
      reset();
    }
  }, [buy, callId, isMoreThan10Secs, messages, product, productId, reset]);

  useEffect(() => {
    vapi.on("speech-start", () => {
      console.log("Speech started");
      stopVoice();
      setIsSpeaking(true);
      setIsActive(true);
      setIsCalling(false);
    });

    vapi.on("speech-end", () => {
      console.log("Assistant speech has ended.");
      setIsSpeaking(false);
    });

    vapi.on("volume-level", (volume) => {
      //   console.log(`Assistant volume level: ${volume}`);
      setVolumeLevel(volume);
    });

    vapi.on("call-end", () => {
      console.log("Call has ended.");
      sendTranscript();
    });

    vapi.on("message", (message: CallMessage) => {
      console.log(message);
      if (message.type === "transcript" && message.transcriptType === "final") {
        const data = messages;
        data.push(message);
        setMessages(data);
        // console.log(message);
      }
    });
  }, [vapi, audio, stopVoice, reset, messages, sendTranscript]);

  // @typescript-eslint/no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const countdown = useCallback(() => {
    if (isActive && !isMoreThan10Secs) {
      let seconds = 10;

      const intervalId = setInterval(() => {
        seconds--;
        if (seconds === 0) {
          clearInterval(intervalId);
          setIsMoreThan10Secs(true);
        }
        return 1;
      }, 1000);
    }
  }, [isActive, isMoreThan10Secs]);

  useEffect(() => {
    setTimeout(() => {
      if (isActive) {
        setShowAddToCart(true);
      }
      countdown();
    }); // Execute every 1 second (1000 milliseconds)
  }, [countdown, isActive]);

  useEffect(() => {
    Config.getIdentifer();
  }, []);

  return (
    <>
      <div>
        <Popup
          trigger={
            <AppButton className="flex" variant="secondary" {...props}>
              <span className="flex items-center justify-center space-x-2">
                <TelOnIcon className="text-sm w-5 h-5" />
                <span>Talk to Us</span>
              </span>
            </AppButton>
          }
          modal
          nested
          closeOnDocumentClick={false}
          closeOnEscape={false}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          open={open}
        >
          <div className="bg-white shadow-xl p-4 z-50 relative max-w-sm mx-auto">
            <ActiveCallDetail
              assistantIsSpeaking={isSpeaking}
              volumeLevel={volumeLevel}
              isCalling={isCalling}
            />
            <button
              onClick={handleOpen}
              className="bg-red-500 text-white py-1 px-2.5 rounded absolute top-4 right-4 rounded-full hover:bg-opacity-70 text-sm"
            >
              X
            </button>
            <div className="flex flex-col items-center justify-center w-full max-w-sm mx-auto">
              <div className="my-4 grid grid-cols-12 gap-4 w-full">
                <div className="text-xs col-span-12 text-center">
                  {/* {callId} */}
                  Our assistant will answer your questions about{" "}
                  <strong>{product?.name}</strong>
                </div>
                {showAddToCart && (
                  <div className="col-span-12">
                    <AddToCartButton
                      onButtonClick={() => {
                        // alert("Clicked");
                        // toast.success("Your item has been added to your cart");
                        toast.promise(addToCart(), {
                          loading: "Adding to cart...",
                          success: <b>Item added to Cart!</b>,
                          error: <b>Could not add to cart.</b>,
                        });
                      }}
                    />
                    <Toaster />
                  </div>
                )}
                {/* <div className="text-xs col-span-12 text-center h-60 overflow-auto">
                  {JSON.stringify(transcript)}
                </div> */}
                {isActive ? (
                  <div className="col-span-6">
                    <AppButton
                      className="col-span-6 bg-green-500"
                      variant="call-to-action"
                      onClick={handleMute}
                    >
                      <span className="flex items-center justify-center space-x-2">
                        {muted ? (
                          <>
                            <MicOffIcon className="text-sm w-5 h-5" /> Unmute
                          </>
                        ) : (
                          <>
                            <MicOnIcon className="text-sm w-5 h-5" /> Mute
                          </>
                        )}
                      </span>
                    </AppButton>
                  </div>
                ) : (
                  <div className={`col-span-6 ${isCalling ? "hidden" : ""}`}>
                    <AppButton
                      className="col-span-6 bg-green-500"
                      variant="call-button"
                      onClick={startCall}
                      disabled={isCalling || isActive}
                    >
                      <span className="flex items-center justify-center space-x-2">
                        <TelOnIcon className="text-sm w-5 h-5" />
                        <span>Start Call</span>
                      </span>
                    </AppButton>
                  </div>
                )}

                {(isCalling || isActive) && (
                  <div className="col-span-6">
                    <AppButton
                      className="col-span-6"
                      variant="danger"
                      onClick={stopCall}
                    >
                      <span className="flex items-center justify-center space-x-2">
                        <TelOffIcon className="text-sm w-5 h-5" />
                        <span>End Call</span>
                      </span>
                    </AppButton>
                  </div>
                )}
                <div className="col-span-12 text-sm">
                  <button
                    className="bg-transparent border-none text-pink-800 font-semibold"
                    type="button"
                    onClick={showHideTranscript}
                  >{`${showTranscript ? "Hide" : "Show"} Transcript`}</button>
                </div>
                {showTranscript && (
                  <div className="col-span-12">
                    <h2 className="font-semibold">Conversation Transcript</h2>
                    <CallTranscript messages={messages}></CallTranscript>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Popup>
      </div>
    </>
  );
};
