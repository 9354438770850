import React from "react";

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | "primary"
    | "secondary"
    | "call-button"
    | "danger"
    | "call-to-action";
}

export const AppButton: React.FC<Props> = ({
  variant = "primary",
  ...props
}) => {
  return (
    <button
      {...props}
      className={`py-2 px-4 hover:bg-opacity-70 w-full font-medium ${
        variant === "primary"
          ? "bg-black text-white"
          : variant === "secondary"
          ? "hover:bg-pink-500 border border-pink-500 text-pink-500 hover:text-white"
          : variant === "call-button"
          ? "bg-green-500 text-white"
          : variant === "danger"
          ? "bg-red-500 text-white"
          : variant === "call-to-action"
          ? "bg-gray-500 text-white"
          : ""
      }`}
    >
      {props.children}
    </button>
  );
};
