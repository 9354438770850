import React from "react";

interface VolumeLevelProps extends React.HTMLAttributes<HTMLDivElement> {
  volume: number;
}

const numBars = 10;

export const VolumeLevel: React.FC<VolumeLevelProps> = ({ volume, ...props }) => {
  return (
    <div className="text-gray-600" {...props}>
      {/* <div className="mb-2">
        <p>Volume Level:</p>
      </div> */}
      <div className="flex">
        {Array.from({ length: numBars }, (_, i) => (
          <div
            key={i}
            className={`w-5 h-5 m-0.5 rounded ${
              i / numBars < volume
                ? " bg-green-500"
                : " bg-gray-300 bg-opacity-40"
            }`}
          />
        ))}
      </div>
      {/* <div>{volume}</div> */}
    </div>
  );
};
