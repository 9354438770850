import React from "react";
import { ConversationTranscript } from "../../interfaces";
import { Config } from "../../config";

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  message: ConversationTranscript;
}

export const TranscriptMessage: React.FC<Props> = ({ message, ...props }) => {
  return (
    <div
      className={`flex flex-col my-2 rounded ${
        message.role === "assistant" ? "items-start mr-8" : "ml-8 items-end"
      }`}
      {...props}
    >
      <div
        className={`text-xs pb-1 ${
          message.role === "assistant" ? "text-left" : "text-right"
        }`}
      >
        {message.role === "assistant" ? `${Config.ASSISTANT_NAME}: ` : "You:"}
      </div>
      <div
        className={`text-sm rounded p-2 bg-opacity-25 ${
          message.role === "assistant"
            ? "text-left bg-orange-500"
            : "bg-blue-500 text-right"
        }`}
      >
        {message.transcript}
      </div>
    </div>
  );
};
