import { v4 as uuidv4 } from "uuid";
import { ClientRequest } from "../assistant";

export class Config {
  static VAPI_PUBLIC_KEY = process.env.REACT_APP_VAPI_PUBKEY ?? "";
  static VAPI_ASSISTANT_ID = process.env.REACT_APP_VAPI_ASSISTANT_ID ?? "";
  static ASSISTANT_NAME = process.env.REACT_APP_ASSISTANT_NAME ?? "Lily";
  static API_HOST = process.env.REACT_APP_API_HOST ?? "http:localhost:3000";
  static PRODUCTS_ENDPOINT = `${Config.API_HOST}/${process.env.REACT_APP_PRODUCTS_ENDPOINT}`;
  static FAQ_ENDPOINT = `${Config.API_HOST}/${process.env.REACT_APP_FAQ_ENDPOINT}`;
  static TRANSCRIPT_ENDPOINT = `${Config.API_HOST}/${process.env.REACT_APP_TRANSCRIPT_ENDPOINT}`;
  static IP_ADDRESS_ENDPOINT = `${Config.API_HOST}/${process.env.REACT_APP_IP_ADDRESS_ENDPOINT}`;
  static identifier = localStorage.getItem("identifier");

  static getLocalItem = (key: string) => {
    return localStorage.getItem(key);
  };

  static setLocalItem = (key: string, data: any) => {
    return localStorage.setItem(key, data);
  };

  static getIdentifer = async () => {
    let identifier = localStorage.getItem("identifier");
    let response = identifier;
    if (!identifier) {
      identifier = uuidv4();
      const ip = await ClientRequest.getClientIp();
      response = `${ip}:${identifier}`;
      localStorage.setItem("identifier", response);
    }
    return response || "";
  };

  static setLastSent = (callId: string) => {
    localStorage.setItem("last_sent", callId);
  };

  static getLastSent = () => {
    return localStorage.getItem("last_sent");
  };
}
