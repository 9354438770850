interface Props extends React.SVGProps<SVGSVGElement> {}

export const TelOffIcon: React.FC<Props> = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.61084 15.3601C6.49361 13.2484 4.71892 10.8192 3.35083 8.16016C3.03976 7.53758 2.93414 6.8324 3.04914 6.146C3.16414 5.4596 3.49384 4.82728 3.99085 4.34009L4.70081 3.62012C5.11674 3.2067 5.67937 2.97461 6.26581 2.97461C6.85225 2.97461 7.41488 3.2067 7.83081 3.62012L9.40082 5.19019C9.81424 5.60612 10.0463 6.16868 10.0463 6.75513C10.0463 7.34157 9.81424 7.90413 9.40082 8.32007L8.0708 9.65015C8.95021 10.8397 9.91617 11.9626 10.9608 13.01"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.2106 15.0601C13.5806 15.3501 13.9506 15.6402 14.3206 15.9102L15.6506 14.5801C15.8552 14.373 16.0989 14.2087 16.3676 14.0964C16.6362 13.9842 16.9244 13.9263 17.2156 13.9263C17.5067 13.9263 17.795 13.9842 18.0636 14.0964C18.3322 14.2087 18.576 14.373 18.7806 14.5801L20.3406 16.1401C20.5477 16.3448 20.7121 16.5885 20.8243 16.8572C20.9366 17.1258 20.9944 17.4139 20.9944 17.7051C20.9944 17.9962 20.9366 18.2846 20.8243 18.5532C20.7121 18.8219 20.5477 19.0654 20.3406 19.27L19.6306 19.99C19.1414 20.484 18.5087 20.8109 17.8227 20.9241C17.1367 21.0372 16.4325 20.9309 15.8105 20.6201C14.0329 19.7179 12.3568 18.6284 10.8105 17.3701"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 2L2 22"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const TelOnIcon: React.FC<Props> = ({ ...props }) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.3308 15.9402L15.6608 14.6101C15.8655 14.403 16.1092 14.2384 16.3778 14.1262C16.6465 14.014 16.9347 13.9563 17.2258 13.9563C17.517 13.9563 17.8052 14.014 18.0739 14.1262C18.3425 14.2384 18.5862 14.403 18.7908 14.6101L20.3508 16.1702C20.5579 16.3748 20.7224 16.6183 20.8346 16.887C20.9468 17.1556 21.0046 17.444 21.0046 17.7351C21.0046 18.0263 20.9468 18.3146 20.8346 18.5833C20.7224 18.8519 20.5579 19.0954 20.3508 19.3L19.6408 20.02C19.1516 20.514 18.5189 20.841 17.8329 20.9541C17.1469 21.0672 16.4427 20.9609 15.8208 20.6501C10.4691 17.8952 6.11008 13.5396 3.35083 8.19019C3.03976 7.56761 2.93414 6.86242 3.04914 6.17603C3.16414 5.48963 3.49384 4.85731 3.99085 4.37012L4.70081 3.65015C5.11674 3.23673 5.67937 3.00464 6.26581 3.00464C6.85225 3.00464 7.41488 3.23673 7.83081 3.65015L9.40082 5.22021C9.81424 5.63615 10.0463 6.19871 10.0463 6.78516C10.0463 7.3716 9.81424 7.93416 9.40082 8.3501L8.0708 9.68018C8.95021 10.8697 9.91617 11.9926 10.9608 13.04C11.9994 14.0804 13.116 15.04 14.3008 15.9102L14.3308 15.9402Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.2109 8.84009C18.0578 8.09677 17.6931 7.41362 17.1609 6.87256C16.6288 6.33149 15.9516 5.95549 15.2109 5.79004"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 7.51001C21.6283 6.19924 20.928 5.00529 19.9655 4.04102C19.003 3.07674 17.8101 2.37408 16.5 2"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
