import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Erro404 from "../Erro404";
import { Product } from "../../interfaces";
import { AddToCartButton, CallButton } from "../../components";
import { Config } from "../../config";
import { ProductRequest } from "../../assistant";
import { CircleLoader } from "react-spinners";

interface DescriptionProps {
  product: Product;
}

const ProductDescription: React.FC<DescriptionProps> = ({ product }) => {
  const description = useMemo(() => product.description.split("\n"), [product]);

  return (
    <>
      {description.map((item, index) => (
        <span key={index}>
          <br />
          {item}
        </span>
      ))}
    </>
  );
};

const SingleProduct: React.FC = () => {
  const { productId } = useParams();

  // const product = useMemo(
  //   () => (productId ? Products.findProduct(productId) : null),
  //   [productId]
  // );

  const [products, setProducts] = useState<Product[]>([]);

  const [product, setProduct] = useState<Product | null>(null);

  const [loading, setLoading] = useState<boolean>(true);

  const getProduct = useCallback(async () => {
    setLoading(true);
    const res = await ProductRequest.find(productId || "");
    setProduct(res);
    setLoading(false);
  }, [productId]);

  const getProducts = useCallback(async () => {
    const res = await ProductRequest.index();
    setProducts(res);
  }, []);

  useEffect(() => {
    getProduct();
    getProducts();
  }, [getProduct, getProducts]);

  const navigate = useNavigate();

  const goToProduct = (item: Product) => {
    navigate(`/${item.slug}/${item.id}`);
  };

  return loading ? (
    <div className="w-full h-screen flex flex-col items-center justify-center text-pink-500">
      <CircleLoader color="pink" />
      Loading...
    </div>
  ) : product ? (
    <div className="max-w-5xl mx-auto m-8">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <h1 className="text-xl font-semibold">{product.name}</h1>
          <h4 className="text-sm">{product.category}</h4>
        </div>
        <div className="col-span-7 bg-gray-200 bg-opacity-70">
          <img src={product.image} alt={product.name} />
        </div>
        <div className="col-span-5 px-4">
          {products.map((p) => (
            <button
              className={`border p-2 truncate text-sm mb-2 mr-2 ${
                product.id === p.id ? " border-pink-500" : "border-gray-600"
              }`}
              onClick={() => goToProduct(p)}
              key={p.id}
            >
              {p.shortName}
            </button>
          ))}

          <div className="my-8 text-3xl font-bold">
            €{product.price}{" "}
            <span className="text-xs font-normal"> Prices incl. VAT </span>
          </div>

          {product.quantity < 1 ? (
            <div className="text-sm text-pink-600 mb-8">
              Soon available again!
            </div>
          ) : (
            product.quantity < 6 && (
              <div className="text-sm text-orange-500 mb-8">
                Almost out of stock ({product.quantity} remaining)!
              </div>
            )
          )}

          {product.features.map((feature) => (
            <span className="block text-sm pb-2" key={feature}>
              ✓ {feature}
            </span>
          ))}

          <div className="my-8">
            <div className="mb-4">
              <AddToCartButton />
            </div>
            <div>
              <CallButton
                productId={product.id}
                assistantId={Config.VAPI_ASSISTANT_ID}
              />
            </div>
          </div>
        </div>
        <div className="col-span-full p-4">
          <ProductDescription product={product} />
        </div>
      </div>

      {/*{JSON.stringify(description)}*/}
      {/*{<ProductDescription product={product} />}*/}
    </div>
  ) : (
    <Erro404 />
  );
};

export default SingleProduct;
