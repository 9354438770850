import React from "react";
import { AssistantSpeechIndicator } from "./AssistantSpeechIndicator";
import { VolumeLevel } from "./VolumeLevel";
import { SupportIcon } from "../icons";
import { PropagateLoader } from "react-spinners";

export interface ActiveCallDetailProps {
  assistantIsSpeaking: boolean;
  isCalling: boolean;
  volumeLevel: number;
}

export const ActiveCallDetail: React.FC<ActiveCallDetailProps> = ({
  assistantIsSpeaking,
  volumeLevel,
  isCalling,
  //   picturePath,
}) => {
  return (
    <div className="flex justify-center items-center w-full h-full p-4">
      <div className="flex flex-col items-center justify-center w-full h-full">
        <span
          className={`w-20 h-20 ${
            assistantIsSpeaking ? " text-green-500" : " text-gray-500"
          }`}
        >
          <SupportIcon />
        </span>

        <AssistantSpeechIndicator
          isSpeaking={assistantIsSpeaking}
          className="mb-4"
        />
        <p className="text-gray-500">
          {isCalling ? (
            <PropagateLoader color="gray" />
          ) : (
            <VolumeLevel volume={volumeLevel} />
          )}
        </p>
      </div>
    </div>
  );
};
