import React, { useCallback, useEffect, useState } from "react";
import { Product } from "../interfaces";
import ProductCard from "./product/ProductCard";
import { ProductRequest } from "../assistant";

const Home: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);

  const init = useCallback(async () => {
    const res = await ProductRequest.index();
    setProducts(res);
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div className="max-w-5xl mx-auto h-screen flex flex-wrap items-center justify-center my-12">
      {products.map((product) => (
        <ProductCard product={product} key={product.id} />
      ))}
    </div>
  );
};

export default Home;
