import React, {useMemo} from "react";
import {Product} from "../../interfaces";
import _ from "lodash";

interface Props {
    product: Product;
}

const ProductCard: React.FC<Props> = ({product}) => {

    const miniDescription = useMemo(() => _.truncate(product.description ?? '', {length: 80}), [product])
    const productUrl = useMemo(() => `/${product.slug}/${product.id}`, [product])

    return (
        <a className="flex flex-col w-64 m-4 justify-start" href={productUrl}>
            <img src={product?.image} className="bg-gray-100 bg-opacity-50" alt={product.name}/>
            <div className="p-2 text-wrap break-words">
                <div className="font-medium truncate" title={product.name}>{product.shortName}</div>
                <div className="break-words text-sm text-gray-600 py-1">{miniDescription}</div>
                <div className="text-lg font-medium">
                    €{product?.price}
                </div>
            </div>
        </a>
    )
}

export default ProductCard;
