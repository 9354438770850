import React from "react";

interface AssistantSpeechIndicatorProps extends React.HTMLAttributes<HTMLDivElement> {
  isSpeaking: boolean;
}

export const AssistantSpeechIndicator: React.FC<
  AssistantSpeechIndicatorProps
> = ({ isSpeaking, ...props }) => {
  return (
    <div className="flex justify-center items-center" {...props}>
      <span
        className={`w-5 h-5 ${
          isSpeaking ? " bg-green-500" : " bg-red-500"
        } mr-3 rounded`}
      />
      {/* <p className="text-gray-500 m-0">
        {isSpeaking ? "Assistant speaking" : "Assistant not speaking"}
      </p> */}
    </div>
  );
};
