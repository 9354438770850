import Vapi from "@vapi-ai/web";

export class VapiAPI {
  static instance = (apiKey: string) => {
    return new Vapi(apiKey);
  };
}


export class DynamicVapiAPI {
  static instance = (apiKey: string) => {
    return new Vapi(apiKey);
  };

  static assistant = (apiKey: string) => {
    return new Vapi(apiKey);
  };
}