import {products} from "../data";

export class Products {
    static getProducts = () => ({
        currency: 'EUR',
        products,
    });

    static shippingPolicy = "The shipping costs are €9,90 for international shipping.\n\nOrders over €99, - have free delivery, unless otherwise stated in the checkout. Delivery will be made within 3 - 4 business days after sending the order confirmation.";

    static returnPolicy = "We want you to be completely satisfied with the purchase of your BWT product. If you are not, we will refund the full purchase price. Please use the contact form to let us know the reason for your dissatisfaction and your contact details. We will then inform you as quickly as possible about the further process and how you can return your product to us free of charge.\n";

    static findProduct = (productId: string | number) => products.find((product) => product.id === productId)
}
