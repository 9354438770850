import React from "react";
import { AppButton } from "./AppButton";

interface Props extends React.ComponentProps<typeof AppButton> {
  onButtonClick?: () => void;
}

export const AddToCartButton: React.FC<Props> = ({ onButtonClick, ...props }) => {
  return <AppButton variant="primary" {...props} onClick={onButtonClick}>Add to Cart</AppButton>;
};
