import React from "react";
import { ConversationTranscript } from "../../interfaces";
import { TranscriptMessage } from "./TranscriptMessage";

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  messages: ConversationTranscript[];
}

export const CallTranscript: React.FC<Props> = ({ messages, ...props }) => {
  return (
    <div className="max-h-72 overflow-auto" {...props}>
      {messages.map((message, index) => (
        <TranscriptMessage key={index} message={message}></TranscriptMessage>
      ))}
    </div>
  );
};
