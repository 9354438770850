import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Home from "./views/Home";
import Error404 from "./views/Erro404";
import SingleProduct from "./views/product/SingleProduct";

function App() {

    return (<Routes>
        <Route index={true} element={<Home/>}/>

        <Route path="/:slug/:productId" element={<SingleProduct/>}/>

        <Route path="*" element={<Error404/>}/>
    </Routes>)
}

export default App;
